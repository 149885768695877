import dynamic from "next/dynamic";
import Typography from "@mui/material/Typography";

const FooterAccordion = dynamic(() => import("./FooterAccordion"))
const FooterLink = dynamic(() => import("./FooterLink"))

const FooterInformation = ({links, type}) => {
    return (
        type == 'desktop' ? (
            <>
                <Typography className="f-size_3 mb-3 uppercase font-regular" component="h5">Information</Typography>
                <ul className="font-light">
                    {
                        links.map((link, index) => {
                            return (
                                <FooterLink
                                    link={link}
                                    key={"info-link-" + index}
                                />
                            );
                        })
                    }
                </ul>
            </>

        ) : (
            <FooterAccordion classes="fw-bold font-regular" title="Information" accordionType="information" type="footer">
                <ul className="font-light">
                    {
                        links.map((link, index) => {
                            return (
                                <FooterLink
                                    link={link}
                                    key={"company_link-" + index}
                                />
                            );
                        })
                    }
                </ul>
            </FooterAccordion>
        )
    );
};

export default FooterInformation;
